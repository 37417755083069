<template>
  <div class="overflow-auto">
    <pro-document-breadcrumbs :breadcrumbs="breadcrumbs" user-role="supplier" />
    <div class="q-pa-md">
      <div v-if="loading">
        <q-skeleton height="50px" style="margin-top: 10px" />
        <q-skeleton height="80px" style="margin-top: 10px" />
      </div>
      <div v-else-if="registrationStatus === 'PendingForApproval'">
        <div style="margin-right: 20px; margin-left: 20px">
          <el-alert
            :title="getRes('System.Message.Error.RegistrationUnderApproval')"
            type="error"
            :closable="false"
          />
        </div>

        <pro-form-render
          mode="View"
          :code="workflowCodeView"
          :form-data-promise="formDataPromise"
        />
      </div>
      <pro-form-render
        v-else
        mode="Edit"
        :code="workflowCode"
        :form-data-promise="formDataPromise"
        @pro-form-submit="redirectToHcmDashboard()"
      />
    </div>
  </div>
</template>

<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs";
import ProFormRender from "@/components/PROSmart/FormBuilder/ProFormRender";

export default {
  components: { ProDocumentBreadcrumbs, ProFormRender },
  data() {
    return {
      breadcrumbs: [
        {
          name: "menu.SupplierRegistrationForm",
          icon: "PROSmart-AccountEdit",
        },
      ],
      loading: true,
      workflowCode: "S_RegForm",
      workflowCodeView: "Supplier_CompanyInfo_View",
      formDataPromise: null,
      registrationStatus: "Created",
    };
  },
  created() {
    this.$proSmart.supplierUi.getExistRegistrationInfo(this).then((info) => {
      const { registrationStatus, modeJson } = info;
      this.registrationStatus = registrationStatus;

      switch (registrationStatus) {
        case "Created":
        case "Rejected":
          this.formDataPromise = Promise.all([
            this.$proSmart.supplierUi.getBasicInfo(this),
            this.$proSmart.supplierUi.getCompanyInfo(this),
          ]).then(([basicInfo, companyInfo]) => ({
            ...basicInfo,
            ...companyInfo,
          }));
          this.loading = false;
          break;
        case "PendingForApproval":
          this.formDataPromise = Promise.resolve(JSON.parse(modeJson));
          this.loading = false;
          break;
        default:
          this.$alert(
            this.getRes("System.Message.Error.NoPermission"),
            this.getRes("notification"),
            { confirmButtonText: this.getRes("ok") }
          );
          this.redirectToHcmDashboard();
      }
    });
  },
  methods: {
    redirectToHcmDashboard() {
      const path = "/SupplierRegistrationForm";
      this.$store.dispatch("menu/removeSubMenu", path);
      this.$router.push({ path: "/Dashboard" });
    },
  },
};
</script>
